import React from 'react'
import Helmet from 'react-helmet'
import '../sass/base.sass'
import Header from '../partials/header.js'
import Contact from '../partials/contact.js'
import Lamps from '../partials/lamps.js'

const title = `Ivan Márer - Prírodný dizajn`
const metaDescription = `Prírodu vnímam ako nevyčerpateľnú inšpiráciu. Motivuje ma svojou jedinečnosťou a nekonečnými tvarmi, ktoré nám ponúka. Verím, že s mojou pomocou si k nej nájdete cestu aj vy a stane sa súčasťou Vašich životov.`

const SEO = () => (
  <Helmet
    htmlAttributes={{
      lang: 'sk',
    }}
    title={title}
    meta={[
      {
        name: `description`,
        content: metaDescription,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: metaDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: metaDescription,
      },
    ]}
  />
)

const IndexPage = () => (
  <div>
    <SEO />
    <Header />
    <Lamps />
    <Contact />
  </div>
)

export default IndexPage
