import React, { useState } from 'react'
import '../sass/lamps.sass'
import Lamp from './lamp.js'
import { LAMPS } from './data/_lamps.js'

const renderLamps = (lamps, setLamps) =>
  lamps.map((lamp, i) => (
    <Lamp
      lamp={lamp}
      key={lamp.name}
      i={i}
      onActiveThumbnailChange={(lamp, thumb) =>
        setLamps(
          lamps.map(l => {
            l.activeThumbnail =
              l.name === lamp.name ? thumb.imgSrc : l.activeThumbnail
            return l
          })
        )
      }
    />
  ))

export default () => {
  const [soldLamps, setSoldLamps] = useState(LAMPS.filter(lamp => lamp.isSold))
  const [forSaleLamps, setForSaleLamps] = useState(LAMPS.filter(lamp => !lamp.isSold))

  return (
    <section className="container" id="lamps">
      {renderLamps(forSaleLamps, setForSaleLamps)}
      {renderLamps(soldLamps, setSoldLamps)}
    </section>
  )
}
