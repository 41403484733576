export const LAMPS = [
  {
    name: "Rebecca",
    type: 'stolová lampa',
    description:
      'Stolová lampa s krásnymi prírodnými krivkami. Roztrojený konár slúžiaci ako nožičky vytvára zaujímavý a štýlový doplnok. Povrch upravený orechovým lakom.',
    techDescription: 'Rozmer: 51 x 22 x 22 (cm), Rozmer tienidla: 22x 24 (cm), Farba tienidla: biela, Typ objímky: E14',
    price: 95,
    isSold: true,
    activeThumbnail: require('../../images/lamps/rebecca/01.jpg'),
    thumbnails: [
      { imgSrc: require('../../images/lamps/rebecca/01.jpg') },
    ],
  },
  {
    name: "Roma",
    type: 'stolová lampa',
    description:
      'Zaujímavo tvarovaný kmeň zo strmého skalnatého brehu. Bukové drevo bolo zbavené kôry, kartáčované a upravené ebenovým lakom.Lampička stojí na bielom drevenom podstavci pre lepšiu stabilitu a zvýraznenie samotného dreva.',
    techDescription: 'Rozmer: 54 x 22 x 20 (cm), Rozmer tienidla: 22x 24 (cm), Farba tienidla: biela, Typ objímky: E14',
    price: 99,
    isSold: true,
    activeThumbnail: require('../../images/lamps/roma/01.jpg'),
    thumbnails: [
      { imgSrc: require('../../images/lamps/roma/01.jpg') },
    ],
  },
  {
    name: "Aimy",
    type: 'stojanový vešiak',
    description:
      'Vešiak z masívneho višňového dreva na kovovej trojnožke upevnenej v kartáčovanom drevenom podstavci.Prírodné drevo v kombinácii s kovom vytvára jedinečný kúsok idustriálneho štýlového doplnku.Aimy má prírodný, moderný a funkčný dizajn.',
    techDescription: 'Rozmer: 200x35x35 (cm)',
    price: 159,
    isSold: false,
    activeThumbnail: require('../../images/lamps/aimy/01.jpg'),
    thumbnails: [
      { imgSrc: require('../../images/lamps/aimy/01.jpg') },
      { imgSrc: require('../../images/lamps/aimy/02.jpg') },
    ],
  },
  {
    name: "Xenia",
    type: 'zrubový nábytok',
    description:
      'Lavičky a police na topánky zo smrekového dreva. Krásne hrče a rozmanitá kresba vytvára jedinečnú atmosféru prírody.Drevo je dokonale opracované brúsením a ošetrené prírodným olejom.',
    techDescription: null,
    price: null,
    isSold: true,
    activeThumbnail: require('../../images/lamps/xenia/01.jpeg'),
    thumbnails: [
      { imgSrc: require('../../images/lamps/xenia/01.jpeg') },
      { imgSrc: require('../../images/lamps/xenia/02.jpeg') },
      { imgSrc: require('../../images/lamps/xenia/03.jpeg') }
    ],
  },
  {
    name: 'Cheryl',
    type: 'stolová lampa',
    description:
      'Jedinečné sfarbenie s výraznými letokruhmi v krásnom čerešnovom dreve zdobia praskliny a vytvárajú tak prírodný originál ako je táto stolová lampička.',
    techDescription:
      'Rozmer: 30x13x10 (cm), Rozmer tienidla:  16x17(cm), Farba tienidla: Čierna, Typ objímky: E14',
    price: 49,
    isSold: true,
    activeThumbnail: require('../../images/lamps/cheryl/01.png'),
    thumbnails: [{ imgSrc: require('../../images/lamps/cheryl/01.png') }],
  },
  {
    name: 'Klement',
    type: 'stolová lampa',
    description:
      'Krásny kmeň čerešňového dreva pripomínajúci tvar nôh poteší oko každého milovníka prírody a bude tým pravým doplnkom pre Váš domov. Drevo je ošetrené lakom a umiestnené na okrúhlom podstavci.',
    techDescription:
      'Rozmer: 52 x 14 x 25 (cm), Rozmer tienidla: 17 x 24 (cm), Farba tienidla: čierna, Typ objímky: E14',
    price: 79,
    isSold: true,
    activeThumbnail: require('../../images/lamps/klement/01.jpg'),
    thumbnails: [{ imgSrc: require('../../images/lamps/klement/01.jpg') }],
  },
  {
    name: 'Leo',
    type: 'stolová lampa',
    description:
      'Bukové drevo s nádhernou kresbou a jedinečným tvarom. Jemne brúsené a upravené matným lakom, aby sa zachoval prírodný vzhľad.',
    techDescription:
      'Rozmer: 42 x 18 x 10 (cm), Rozmer tienidla: 17 x 24 (cm), Farba tienidla: čierna, Typ objímky: E14',
    price: 59,
    isSold: true,
    activeThumbnail: require('../../images/lamps/leo/01.jpg'),
    thumbnails: [{ imgSrc: require('../../images/lamps/leo/01.jpg') }],
  },
  {
    name: 'Cyril',
    type: 'stolová lampa',
    description:
      'Malá stolová lampa z bukového dreva dostala svoje meno vďaka tvaru, v ktorom rástla a ktorým Vám ho bude pripomínať. Jej krásna kresba je ošetrená ebenovým lakom a zdobená bielym tienidlom.',
    techDescription:
      'Rozmer: 40 x 20 x 8 (cm), Rozmer tienidla: 17 x 24 (cm), Farba tienidla: biela, Typ objímky: E14',
    price: 59,
    isSold: true,
    activeThumbnail: require('../../images/lamps/cyril/02.jpg'),
    thumbnails: [{ imgSrc: require('../../images/lamps/cyril/02.jpg') }],
  },
  {
    name: 'Vincenzo',
    type: 'stojan na víno',
    description:
      'Vaše obľúbené vína vložené v precízne spracovanom kmeni, budú pri každom pohľade Vás alebo vašich známych vzbudzovať jedinečný dojem spojenia prírody a pohodlia domova.',
    techDescription: 'Rozmer: 115 x 90 x 50 (cm)',
    price: 139,
    isSold: false,
    activeThumbnail: require('../../images/lamps/vincenzo/01.jpg'),
    thumbnails: [
      { imgSrc: require('../../images/lamps/vincenzo/01.jpg') },
      { imgSrc: require('../../images/lamps/vincenzo/02.jpg') },
    ],
  },
  {
    name: 'Anabelle',
    type: 'stolová lampa',
    description:
      'Stolová lampa pripomínajúca sediacu postavu bude jedinečným doplnkom pre Váš domov. Jej drevo je opaľované a ošetrované lakom.',
    techDescription:
      'Rozmer: 32 x 14 x 21 (cm), Rozmer tienidla: 13 x 13 (cm), Farba tienidla: Biela, Typ objímky: E14',
    price: 79,
    isSold: false,
    activeThumbnail: require('../../images/lamps/anabelle/01-01.jpg'),
    thumbnails: [
      { imgSrc: require('../../images/lamps/anabelle/01-01.jpg') },
      { imgSrc: require('../../images/lamps/anabelle/01-02.jpg') },
    ],
  },
  {
    name: 'Betty',
    type: 'stolová lampa',
    description:
      'V jednoduchosti je krása. Práve o tomto je táto trojnohá stolová lampička vyrobená zo smrekového dreva a upravená lakom.',
    techDescription:
      'Rozmer: 33 x 18 x 15 (cm), Rozmer tienidla: 12 x 15 (cm), Farba tienidla: Čierna, Typ objímky: E14',
    price: 59,
    isSold: true,
    activeThumbnail: require('../../images/lamps/betty/02-01.jpg'),
    thumbnails: [{ imgSrc: require('../../images/lamps/betty/02-01.jpg') }],
  },
  {
    name: 'Celine',
    type: 'stolová lampa',
    description:
      'Stolová lampa, ktorá svojim tvarom pripomína tanec. Oblými tvarmi poteší Vaše oči. Vyrobená z opalovaného smrekového dreva, ošetrená lakom.',
    techDescription:
      'Rozmer: 53 x 37 x 25 (cm), Rozmer tienidla: 15 x 30 (cm), Farba tienidla: Čierna, Typ objímky: E14',
    price: 89,
    isSold: true,
    activeThumbnail: require('../../images/lamps/celine/03-01.jpg'),
    thumbnails: [
      { imgSrc: require('../../images/lamps/celine/03-01.jpg') },
      { imgSrc: require('../../images/lamps/celine/03-02.jpg') },
    ],
  },
  {
    name: 'Eleanor',
    type: 'stojanová lampa',
    description:
      'Peň starého stromu, na ktorom rástol tento unikát bol pre mňa dostatočnou inšpiráciou. Lampa je ebenovej farby a ošetrená lakom.',
    techDescription:
      'Rozmer: 132 x 70 x 70 (cm), Rozmer tienidla: 36 x 50 (cm), Farba tienidla: Biela, Typ objímky: E27',
    price: 179,
    isSold: true,
    activeThumbnail: require('../../images/lamps/eleanor/05-01.jpg'),
    thumbnails: [
      { imgSrc: require('../../images/lamps/eleanor/05-01.jpg') },
      { imgSrc: require('../../images/lamps/eleanor/05-02.jpg') },
    ],
  },
  {
    name: 'Freddie',
    type: 'konferenčný stolík',
    description:
      'Moderný stôl z prírodného materiálu v kombinácii s kovovými nohami. Masívne jaseňové drevo je ručne opracované s ponechanými prasklinkami, aby bola zvýraznená prirodzenosť a jedinečnosť dreva.',
    techDescription:
      'Rozmer: 40 x 110 x 70 (cm)',
    price: 299,
    isSold: true,
    activeThumbnail: require('../../images/lamps/freddie/02.jpg'),
    thumbnails: [
      { imgSrc: require('../../images/lamps/freddie/01.jpg') },
      { imgSrc: require('../../images/lamps/freddie/02.jpg') },
    ],
  },
  {
    name: 'Frida',
    type: 'stojanová lampa',
    description:
      'Špecifická koreňmi rastúcimi nad zemou, ktoré lampe dodávajú výbornú stabilitu a moderný vzhľad. Jemne opálené smrekové drevo ošetrené lakom.',
    techDescription:
      'Rozmer: 116 x 74 x 60 (cm), Rozmer tienidla: 36 x 50 (cm), Farba tienidla: Biela, Typ objímky: E27',
    price: 179,
    isSold: false,
    activeThumbnail: require('../../images/lamps/frida/06-01.jpg'),
    thumbnails: [{ imgSrc: require('../../images/lamps/frida/06-01.jpg') }],
  },
  {
    name: 'Julliet',
    type: 'stolová lampa',
    description:
      'Z tejto bukovej hrče by určite nebolo toľko tepla, koľko prinesie svetla do Vášho domova. Bukové drevo ošetrené matným lakom.',
    techDescription:
      'Rozmer: 36 x 16 x 10 (cm), Rozmer tienidla: 12 x 15 (cm), Farba tienidla: Čierna, Typ objímky: E14',
    price: 69,
    isSold: true,
    activeThumbnail: require('../../images/lamps/julliet/10-01.jpg'),
    thumbnails: [{ imgSrc: require('../../images/lamps/julliet/10-01.jpg') }],
  },
  {
    name: 'Dominica',
    type: 'stolová lampa',
    description:
      'Pod zemou ukryté korene odhalené vyvrátením stromu sú naozaj tým pravým štýlovým doplnkom. Smrekové drevo ošetrené lakom.',
    techDescription:
      'Rozmer: 53 x 30 x 25 (cm), Rozmer tienidla: 15 x 30 (cm), Farba tienidla: Čierna, Typ objímky: E14',
    price: 89,
    isSold: true,
    activeThumbnail: require('../../images/lamps/dominico/04-01.jpg'),
    thumbnails: [{ imgSrc: require('../../images/lamps/dominico/04-01.jpg') }],
  },
  {
    name: 'Karoline',
    type: 'stolová lampa',
    description:
      'Rozdvojená lampa s dvojitým efektom. Cez deň poslúži ako dekorácia a večer posvieti pri knihe. Smrekové drevo ošetrené ebenovým lakom.',
    techDescription:
      'Rozmer: 35 x 19 x 15 (cm), Rozmer tienidla: 13 x 13 (cm), Farba tienidla: Krémová, Typ objímky: E14',
    price: 79,
    isSold: true,
    activeThumbnail: require('../../images/lamps/karoline/11-01.jpg'),
    thumbnails: [{ imgSrc: require('../../images/lamps/karoline/11-01.jpg') }],
  },
  {
    name: 'Hilma',
    type: 'stojanová lampa',
    description:
      'Skalnatý podklad sa podpísal pod jedinečný tvar tejto lampy. Prírodné smrekové drevo ošetrené lakom.',
    techDescription:
      'Rozmer: 130 x 82 x 50 (cm), Rozmer tienidla: 36 x 50 (cm), Farba tienidla: Biela, Typ objímky: E27',
    price: 149,
    isSold: true,
    activeThumbnail: require('../../images/lamps/hilma/08-01.jpg'),
    thumbnails: [
      { imgSrc: require('../../images/lamps/hilma/08-01.jpg') },
      { imgSrc: require('../../images/lamps/hilma/08-02.jpg') },
      { imgSrc: require('../../images/lamps/hilma/08-03.jpg') },
    ],
  },
  {
    name: 'Issaq',
    type: 'konferenčný stolík',
    description:
      'Zaujímavý konferenčný stolík z masívneho topoľového dreva. Neprirodzený tvar a praskliny sú súčasťou jeho dizajnu. Stolík je upravený brúsením a lakovaním.',
    techDescription: 'Rozmer: 50 x 90 x 85 (cm)',
    price: 199,
    isSold: true,
    activeThumbnail: require('../../images/lamps/isabelle/09-01.jpg'),
    thumbnails: [{ imgSrc: require('../../images/lamps/isabelle/09-01.jpg') }],
  },
  {
    name: 'Gizelle',
    type: 'stojanová lampa',
    description:
      'Rozmanité korene stromu slúžiace ako nohy tejto lampy vytvoria vo Vašom dome jedinečnú atmosféru. Smrekové drevo ošetrené orechovým lakom.',
    techDescription:
      'Rozmer: 132 x 80 x 50 (cm), Rozmer tienidla: 36 x 50 (cm), Farba tienidla: Biela káva, Typ objímky: E27',
    price: 199,
    isSold: true,
    activeThumbnail: require('../../images/lamps/gizelle/07-01.jpg'),
    thumbnails: [
      { imgSrc: require('../../images/lamps/gizelle/07-01.jpg') },
      { imgSrc: require('../../images/lamps/gizelle/07-02.jpg') },
    ],
  },
]
