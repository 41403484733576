import React from 'react'
import '../sass/contact.sass'
import logoSrc from '../images/logo.png'

const Contact = () => (
  <section
    id="contact"
    className="d-flex justify-content-center align-items-center"
  >
    <div className="text-center py-5">
      <h1 className="py-5">Kontakt</h1>
      <p className="name pb-4">Ivan Márer</p>
      <p>
        <a href="tel:+421918394553">+421 918 394 553</a>
      </p>
      <p>
        <a href="mailto:kontakt@ivanmarer.sk">kontakt@ivanmarer.sk</a>
      </p>
      <img src={logoSrc} className="mt-5" alt="Ivan Márer - logo" />
    </div>
  </section>
)

export default Contact
