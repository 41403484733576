import React from 'react'

export default props => {
  const renderAvailability = lamp => (
    <small>
      {lamp.isSold ? (
        <span className="sold">Predané</span>
      ) : (
        <span className="available">Dostupné</span>
      )}
    </small>
  )

  return (
    <article className="my-5 py-5">
      <div className="row">
        <div
          className={
            'col justify-content-center d-flex flex-column' +
            (props.i % 2 !== 0
              ? ' order-1 order-md-1 text-md-right'
              : ' order-1 order-md-2')
          }
        >
          <h2>{props.lamp.name}</h2>
          <small>{props.lamp.type}</small>
          {renderAvailability(props.lamp)}
          <p className="py-3 description">{props.lamp.description}</p>
          <small className="pb-5">{props.lamp.techDescription}</small>
          <div
            className={'price' + (props.lamp.isSold ? ' sold' : ' available')}
          >
            {props.lamp.price ? (
              <span className="py-2 px-5 text-center d-block d-md-inline-block">
                {props.lamp.price} €
              </span>
            ) : null}
          </div>
        </div>
        <div
          className={
            'col-12 col-md-5' +
            (props.i % 2 !== 0 ? ' order-0 order-md-2' : ' order-0 order-md-1')
          }
        >
          <img src={props.lamp.activeThumbnail} alt={props.lamp.name} />
          <div className="gallery row d-none d-md-flex">
            {props.lamp.thumbnails
              .filter(thumb => props.lamp.activeThumbnail !== thumb.imgSrc)
              .map((thumb, i) => (
                <div
                  className="thumb col-md-4 col-12"
                  key={thumb.imgSrc}
                  onClick={() =>
                    props.onActiveThumbnailChange(props.lamp, thumb)
                  }
                >
                  <img src={thumb.imgSrc} alt={props.lamp.name} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </article>
  )
}
