import React from 'react'
import '../sass/header.sass'
import heroImgSrc from '../images/hero.jpg'

const Header = () => (
  <div id="hero" style={{ backgroundImage: `url(${heroImgSrc})` }}>
    <header className="container py-md-5 pt-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
      <h1>
        <a href="/">
          Ivan Márer<small className="d-block">Prírodný dizajn</small>
        </a>
      </h1>
      <nav className="d-none d-md-block">
        <ul className="nav justify-content-end">
          <li className="nav-item">
            <a href="#lamps" className="nav-link">
              Ponuka
            </a>
          </li>
          <li className="nav-item">
            <a href="#contact" className="nav-link">
              Kontakt
            </a>
          </li>
        </ul>
      </nav>
    </header>
    <div className="section" id="intro">
      <div className="container h-100">
        <div className="row py-md-5 h-100">
          <div className="offset-md-1" />
          <div className="col-md-10 align-items-center d-flex text-center">
            <p className="intro mt-5 mt-md-5 py-md-5 px-md-5">
              Prírodu vnímam ako nevyčerpateľnú inšpiráciu. Motivuje ma svojou
              jedinečnosťou a nekonečnými tvarmi, ktoré nám ponúka. Verím, že s
              mojou pomocou si k nej nájdete cestu aj vy a stane sa súčasťou
              Vašich životov.
              <span className="d-block text-right pt-5">- Ivan Márer</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Header
